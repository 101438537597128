import React from 'react';
import { graphql } from 'gatsby';

import Seo from '../components/seo';

import StoreLocator from '../screens/locator';

const IndexPage = ({ data }) => {
  const { stores } = data;
  const finalStores = stores.nodes.map((s) => ({
    ...s,
    lat: parseFloat(s.lat?.replace(',', '.')),
    lng: parseFloat(s.lng?.replace(',', '.')),
  }));

  return (
    <>
      <Seo />
      <StoreLocator stores={finalStores} />
    </>
  );
};

export const query = graphql`
  query {
    stores: allGoogleSpreadsheetStores {
      nodes {
        id
        street: rue
        postalCode: cp
        address: adresseComplete
        lat: latitude
        lng: longitude
        name: client
        phone: telephone
        town: ville
        campaign: participe
        # rtf
      }
    }
  }
`;

export default IndexPage;
